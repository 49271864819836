import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { map } from 'rxjs/operators/map'; 
import { Observable } from 'rxjs/Observable';
import { GLOBAL } from './global';	

@Injectable()
export class UsuarioService{

	public url: string;
	public identity;
	public token;

	constructor(private _http: Http){
		this.url = GLOBAL.url;
	}
	register(user_to_register){
		let params = JSON.stringify(user_to_register);
		let headers = new Headers({'Content-Type':'application/json'});
		return this._http.post(this.url+'auth/registro', params, {headers: headers}).pipe(map(res => res.json()));
						 
		//return "Texto desde el Servicio";
	}
	login(user_to_login, gettoken = null){
		if(gettoken != null){
			user_to_login.gettoken = gettoken;
		}
		let params = JSON.stringify(user_to_login);
		let headers = new Headers({'Content-Type':'application/json'});
		return this._http.post(this.url+'auth/login', params, {headers: headers}).pipe(map(res => res.json()));
	}
	getIdentity(){
		let identity = JSON.parse(localStorage.getItem('identity'));
		if(identity != "undefined"){
			this.identity = identity;
		}else{
			this.identity = null;
		}
		return this.identity;
	}
	getToken(){
		let token = localStorage.getItem('token');
		if(token != "undefined"){
			this.token = token;
		}else{
			this.token = null;
		}
		return this.token;
	}
	actualizarUsuario(actualizar_usuario_nombre){
		let params = JSON.stringify(actualizar_usuario_nombre);
		let headers = new Headers({
			'Content-Type':'application/json',
			'Authorization': this.getToken()
		});
		return this._http.put(this.url+'auth/update', params, {headers: headers}).pipe(map(res => res.json()));
	}
}