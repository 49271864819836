import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Usuario } from '../../models/usuario';
import { GLOBAL } from '../../services/global';
import { UsuarioService } from '../../services/usuario.service';

@Component({
	selector: 'registro',
	templateUrl: 'registro.component.html',
	styleUrls: ['registro.component.css']
})

export class RegistroComponent implements OnInit{

	public title:string;
	public usuario: Usuario;
	public mensaje:string;
	public cod:string;
	public identity;
	constructor(private _route: ActivatedRoute, private _router: Router, private _usuarioService: UsuarioService){
		this.title = 'Registro';
		this.usuario = new Usuario('','','','','','');	
	}
	ngOnInit(){
		this.identity = this._usuarioService.getIdentity();
	    if(this._usuarioService.getToken()){
	      this._router.navigate(['/inicio']);
	    }
	}
	onSubmit(){
		this._usuarioService.register(this.usuario).subscribe(
			response => {
				if(response.mensaje.codigo == 'success'){
					this.cod = response.mensaje.codigo;
					this.mensaje = response.mensaje.msg;
					this.usuario = new Usuario('','','','','','');	
				}else{
					this.cod = response.mensaje.codigo;
					this.mensaje = response.mensaje.msg;
				}
				
			},
			error => {
				this._router.navigate(['/inicio']);
			}
		);
	}
}