import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { routing, appRoutingProviders } from './app.routing';
import { MaterialModule } from './material.module';

//guards
import { AdminGuard } from './services/admin.guard';
import { RoleGuard } from './services/role.guard';
//component
import { AppComponent } from './app.component';
import { InicioComponent} from './component/inicio/inicio.component';
import { AlumnosComponent} from './component/alumnos/alumnos.component';
import { CursoComponent} from './component/curso/curso.component';
import { LoginComponent} from './component/login/login.component';
import { RegistroComponent} from './component/registro/registro.component';
import { UsuarioEditComponent} from './component/usuario-edit/usuario-edit.component';
import { ListadoCursoIdComponent } from './component/listado-curso-id/listado-curso-id.component';
import { ListadoCursoIdUrlComponent } from './component/listado-cursos-id-url/listado-cursos-id-url.component';
import { FormularioComponent } from './component/formulario/formulario.component';
import { FormularioRespuestaComponent } from './component/formulario-respuesta/formulario-respuesta.component';
import { FormularioRespuestaVerComponent } from './component/formulario-respuesta-ver/formulario-respuesta-ver.component';
import { ListadoCursoIdUrlLeccionComponent } from './component/listado-curso-id-url-leccion/listado-curso-id-url-leccion.component';
import { LeccionEtapaComponent } from './component/leccion-etapa/leccion-etapa.component';
import { ListaAlumnosFormulariosComponent } from './component/listado-alumnos-formularios/listado-alumnos-formularios.component';
import { ListaAlumnosFormulariosVerComponent } from './component/listado-alumnos-formularios-ver/listado-alumnos-formularios-ver.component';
import { ListaAlumnosFormulariosVerIdComponent } from './component/listado-alumnos-formularios-ver-id/listado-alumnos-formularios-ver-id.component';


//Servicios
import { UsuarioService } from './services/usuario.service';
import { CursoService } from './services/curso.service';
import { AlumnoService } from './services/alumno.service';

@NgModule({
  declarations: [
    AppComponent,
    InicioComponent,
    CursoComponent,
    LoginComponent,
    RegistroComponent,
    UsuarioEditComponent,
    AlumnosComponent,
    ListadoCursoIdComponent,
    ListadoCursoIdUrlComponent,
    FormularioComponent,
    FormularioRespuestaComponent,
    FormularioRespuestaVerComponent,
    ListadoCursoIdUrlLeccionComponent,
    LeccionEtapaComponent,
    ListaAlumnosFormulariosComponent,
    ListaAlumnosFormulariosVerComponent,
    ListaAlumnosFormulariosVerIdComponent
  ],
  imports: [
    BrowserModule,
    routing,
    FormsModule,
    HttpModule,
    BrowserAnimationsModule,
    MaterialModule,
  ],
  providers: [
  appRoutingProviders,
  UsuarioService,
  CursoService,
  AlumnoService,
  AdminGuard,
  RoleGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
