import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { GLOBAL } from '../../services/global';
import { AlumnoService } from '../../services/alumno.service';
import { Alumno } from '../../models/alumno';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Component({
	selector: 'alumnos',
	templateUrl: './alumnos.component.html'
})

export class AlumnosComponent implements OnInit{
	  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  
    .pipe(
      map(result => result.matches)
    );
	public title:string;
	public alumno: Alumno[];
	constructor(private _route: ActivatedRoute, private _router: Router, private _alumnoService: AlumnoService, private breakpointObserver: BreakpointObserver){
		this.title = 'Alumnos';	
	}
	ngOnInit(){
		this._alumnoService.getListaAlumnos().subscribe(
			response => {
				if(!response.dato){
				}else{
					this.alumno = response.dato;
				}
			},
			error => {
				this._router.navigate(['/inicio']);
			}
		);
	}
}