import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { GLOBAL } from '../../services/global';
import { AlumnoService } from '../../services/alumno.service';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Component({
	selector: 'listado-alumnos-formularios-ver',
	templateUrl: './listado-ver-df.component.html'
})

export class ListaAlumnosFormulariosVerComponent implements OnInit{
	  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  
    .pipe(
      map(result => result.matches)
    );
	public titulo:string;
	public data:string;
	public url_usuario_enviar:string;
	public url_formulario_enviar:string;
	
	constructor(private _route: ActivatedRoute, private _router: Router, private _alumnoService: AlumnoService, private breakpointObserver: BreakpointObserver){
		this.titulo = 'Preguntas';	
	}
	ngOnInit(){
		this._route.params.forEach((params: Params) =>{
			let url_usuario = params['url_usuario'];
			let url_formulario = params['url_formulario'];
			this._alumnoService.getListaAlumnosFormularioVer(url_formulario,url_usuario).subscribe(
				response => {
					if(!response.dato){
						this._router.navigate(['/alumnos']);
					}else{
						this.data = response.dato;
						this.url_usuario_enviar = url_usuario;
						this.url_formulario_enviar = url_formulario;
					}
				},
				error => {
					this._router.navigate(['/alumnos']);
				}
			);
		});
	}
}