import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Usuario } from '../../models/usuario';
import { GLOBAL } from '../../services/global';
import { UsuarioService } from '../../services/usuario.service';

@Component({
	selector: 'login',
	templateUrl: 'login.component.html',
	styleUrls: ['login.component.css']
})

export class LoginComponent implements OnInit{
	public title:string;
	public usuario: Usuario;
	public identity;
	public token;
	public codigo: string;
	public mensaje: string;
	constructor(private _route: ActivatedRoute, private _router: Router, private _usuarioService: UsuarioService){
		this.title = 'Login';
		this.usuario = new Usuario('','','','','','');	
	}
	ngOnInit(){
    this.identity = this._usuarioService.getIdentity();
	if(this._usuarioService.getToken()){
	    this._router.navigate(['/inicio']);
	}
	}
	onSubmit(){
		this._usuarioService.login(this.usuario).subscribe(
			response => {
				this.identity = response.usuario;
				if(!this.identity || !response.usuario.datos){
					this.codigo = 'danger';
					this.mensaje = 'No se logeo correctamente';
				}else{
					localStorage.setItem('identity', JSON.stringify(this.identity.datos));
					this._usuarioService.login(this.usuario, 'true').subscribe(
						response => {
							this.token = response.usuario.token;
							if(this.token.length <= 0){
								this.codigo = response.usuario.codigo;
					            this.mensaje = response.usuario.msg;
							}else{
								localStorage.setItem('token',this.token);
								this.codigo = response.usuario.codigo;
					            this.mensaje = response.usuario.msg;
					            this._router.navigate(['/']);
							}
						},
						error => {
							var errorMensaje = <any>error;
							if(errorMensaje != null){
								var body = JSON.parse(error._body);
								this.codigo = body.usuario.codigo;
								this.mensaje = body.usuario.msg;
							}
						}
					);
				}
			},
			error => {
				var errorMensaje = <any>error;
				if(errorMensaje != null){
					var body = JSON.parse(error._body);
					this.codigo = body.usuario.codigo;
					this.mensaje = body.usuario.msg;
				}
			}
		);
	}
}