import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CursoService } from '../../services/curso.service';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
	selector: 'listado-cursos-id-url',
	templateUrl: './listado-cursos-id-url.component.html'
})

export class ListadoCursoIdUrlComponent implements OnInit{
	  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  
    .pipe(
      map(result => result.matches)
    );
	public leccion:string;
	public etapa;
	public descripcion;
	public formulario:string;
	public url_curso_a:string;
	public url_etapa_a:string;
	constructor(private _route: ActivatedRoute, private _router: Router, private _cursoService: CursoService, private breakpointObserver: BreakpointObserver){
	}	
	ngOnInit(){
		this.getListadoCursosIdUrl();
	}
	getListadoCursosIdUrl(){
		this._route.params.forEach((params: Params) =>{
			let url_curso = params['url_curso'];
			let url_etapa = params['url_etapa'];
			this._cursoService.getListadoCursosIdUrl(url_curso,url_etapa).subscribe(
				response => {
					if(response.cursos.url){
						this._router.navigate(['/curso']);
					}else{
						this.url_curso_a = url_curso;
						this.url_etapa_a = url_etapa;
						this.leccion = response.cursos.leccion;
						this.etapa = response.cursos.etapa;
						this.descripcion = response.cursos.etapa.descripcion;
						this.formulario = response.cursos.formulario;
					}
				},
				error => {
					this._router.navigate(['/curso']);
				}
			);
		});
	}
}