import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { map } from 'rxjs/operators/map'; 
import { Observable } from 'rxjs/Observable';
import { GLOBAL } from './global';	

@Injectable()
export class CursoService{

	public url: string;
	public identity;
	public token;

	constructor(private _http: Http){
		this.url = GLOBAL.url;
	}
	getToken(){
		let token = localStorage.getItem('token');
		if(token != "undefined"){
			this.token = token;
		}else{
			this.token = null;
		}
		return this.token;
	}
	getCursos(){
		let headers = new Headers({'Content-Type':'application/json','Authorization': this.getToken()});
		let options = new RequestOptions({headers: headers});
		return this._http.get(this.url+'usuario/listadoCursos', options).pipe(map(res => res.json()));
	}
	getListadoCursosId(id_curso){
		let headers = new Headers({'Content-Type':'application/json','Authorization': this.getToken()});
		let options = new RequestOptions({headers: headers});
		return this._http.get(this.url+'usuario/listadoCursosId/'+id_curso, options).pipe(map(res => res.json()));
	}
	getListadoCursosIdUrl(id_curso,id_etapa){
		let headers = new Headers({'Content-Type':'application/json','Authorization': this.getToken()});
		let options = new RequestOptions({headers: headers});
		return this._http.get(this.url+'usuario/listadoCursosIdUrl/'+id_curso+'/'+id_etapa, options).pipe(map(res => res.json()));
	}
	getListadoCursosIdUrlLeccion(id_curso,id_etapa,url_leccion){
		let headers = new Headers({'Content-Type':'application/json','Authorization': this.getToken()});
		let options = new RequestOptions({headers: headers});
		return this._http.get(this.url+'usuario/listadoCursosIdUrlLeccion/'+id_curso+'/'+id_etapa+'/'+url_leccion, options).pipe(map(res => res.json()));
	}
	getListadoCursosIdUrlLeccionLista(id_curso,id_etapa,url_leccion,url_etapa_leccion){
		let headers = new Headers({'Content-Type':'application/json','Authorization': this.getToken()});
		let options = new RequestOptions({headers: headers});
		return this._http.get(this.url+'usuario/listadoCursosIdUrlLeccionLista/'+id_curso+'/'+id_etapa+'/'+url_leccion+'/'+url_etapa_leccion, options).pipe(map(res => res.json()));
	}
	//Formulario
	getFormulario(id_curso,id_etapa,url_formulario){
		let headers = new Headers({'Content-Type':'application/json','Authorization': this.getToken()});
		let options = new RequestOptions({headers: headers});
		return this._http.get(this.url+'usuario/formulario/'+id_curso+'/'+id_etapa+'/'+url_formulario, options).pipe(map(res => res.json()));
	}
	getRespuesta(id_curso,id_etapa,url_formulario,id_formulario){
		let headers = new Headers({'Content-Type':'application/json','Authorization': this.getToken()});
		let options = new RequestOptions({headers: headers});
		return this._http.get(this.url+'usuario/formularioId/'+id_curso+'/'+id_etapa+'/'+url_formulario+'/'+id_formulario, options).pipe(map(res => res.json()));
	}
	getRespuestaVer(id_curso,id_etapa,url_formulario,id_formulario){
		let headers = new Headers({'Content-Type':'application/json','Authorization': this.getToken()});
		let options = new RequestOptions({headers: headers});
		return this._http.get(this.url+'usuario/formularioIdRespuesta/'+id_curso+'/'+id_etapa+'/'+url_formulario+'/'+id_formulario, options).pipe(map(res => res.json()));
	}
	register(respuesta,id_curso,id_etapa,url_formulario,id_formulario){
		let params = JSON.stringify(respuesta);
		let headers = new Headers({'Content-Type':'application/json','Authorization': this.getToken()});
		return this._http.post(this.url+'usuario/registroPregunta/'+id_curso+'/'+id_etapa+'/'+url_formulario+'/'+id_formulario, params, {headers: headers}).pipe(map(res => res.json()));		 
		//return "Texto desde el Servicio";
	}
}