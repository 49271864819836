import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Usuario } from '../../models/usuario';
import { GLOBAL } from '../../services/global';
import { UsuarioService } from '../../services/usuario.service';
@Component({
	selector: 'usuario-edit',
	templateUrl: './usuario-edit.component.html',
	styleUrls: ['./usuario-edit.component.css']
})
export class UsuarioEditComponent implements OnInit {
	public usuario: Usuario;
	public identity;
	public token;
	public mensaje:string;
	public cod:string;

	constructor(private _usuarioService: UsuarioService) {
		this.identity = this._usuarioService.getIdentity();
		this.token = this._usuarioService.getToken();
		this.usuario = this.identity;
	}
	ngOnInit(){

	}
	onSubmit(){
		this._usuarioService.actualizarUsuario(this.usuario).subscribe(
			response => {
				if(!response.mensaje){
					this.cod = 'danger';
					this.mensaje = 'error en el sistema, volver a intentarlso';
				}else{
					this.cod = response.mensaje.codigo;
					this.mensaje = response.mensaje.msg;
					localStorage.setItem('identity', JSON.stringify(this.usuario));
				}
			},
			error => {
				//console.log(<any>error);
				this.cod = error.mensaje.codigo;
				this.mensaje = error.mensaje.msg;
			}
		);
	}
}