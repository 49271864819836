import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { GLOBAL } from '../../services/global';
import { AlumnoService } from '../../services/alumno.service';
import {NgForm} from '@angular/forms';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Component({
	selector: 'listado-alumnos-formularios-ver-id',
	templateUrl: './formularios-ver-id.component.html'
})

export class ListaAlumnosFormulariosVerIdComponent implements OnInit{
	  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  
    .pipe(
      map(result => result.matches)
    );
	public titulo:string;
	public data:string;
	public url_usuario_enviar:string;
	public url_formulario_enviar:string;
	public respuesta;
	public mensaje:string;
	public cod:string;
	constructor(private _route: ActivatedRoute, private _router: Router, private _alumnoService: AlumnoService, private breakpointObserver: BreakpointObserver){
		this.titulo = 'Agregar Notas';	
	}
	ngOnInit(){
		this._route.params.forEach((params: Params) =>{
			let url_usuario = params['url_usuario'];
			let url_formulario = params['url_formulario'];
			let id_respuesta_formulario = params['id_respuesta'];
			this._alumnoService.getListaAlumnosFormularioVerId(url_formulario,url_usuario,id_respuesta_formulario).subscribe(
				response => {
					if(!response.dato){
						this._router.navigate(['/alumnos']);
					}else{
						this.data = response.dato;
						this.url_usuario_enviar = url_usuario;
						this.url_formulario_enviar = url_formulario;
					}
				},
				error => {
					this._router.navigate(['/alumnos']);
				}
			);
		});
	}
	onSubmit(preguntaForm: NgForm){
		this._route.params.forEach((params: Params) =>{
			this.respuesta = preguntaForm.value;
			let url_usuario = params['url_usuario'];
			let url_formulario = params['url_formulario'];
			let id_respuesta_formulario = params['id_respuesta'];
			this._alumnoService.actualizarRespuesta(this.respuesta,url_formulario,url_usuario,id_respuesta_formulario).subscribe(
				response => {
					if(response.mensaje.codigo == 'success'){
						this.cod = response.mensaje.codigo;
					    this.mensaje = response.mensaje.msg;
					}else{
						this.cod = response.mensaje.codigo;
					    this.mensaje = response.mensaje.msg;
					}
				}, 
				error =>{
					this.cod = error._body.mensaje.codigo;
					this.mensaje = error._body.mensaje.msg;
				}
			);
		})
	}
}