import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CursoService } from '../../services/curso.service';
import {NgForm} from '@angular/forms';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Component({
	selector: 'formulario-respuesta',
	templateUrl: './formulario-respuesta.component.html'
})

export class FormularioRespuestaComponent implements OnInit{
	  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  
    .pipe(
      map(result => result.matches)
    );
	public formulario:string;
	public url_curso_a:string;
	public url_etapa_a:string;
	public url_formulario_c:string;
	public datos:string;
	public respuesta;
	public mensaje:string;
	public cod:string;
	constructor(private _route: ActivatedRoute, private _router: Router, private _cursoService: CursoService, private breakpointObserver: BreakpointObserver){
	}	
	ngOnInit(){
		this.getListadoFormularioRespuesta();
	}
	getListadoFormularioRespuesta(){
		this._route.params.forEach((params: Params) =>{
			let url_curso = params['url_curso'];
			let url_etapa = params['url_etapa'];
			let url_formulario = params['url_formulario'];
			let id_formulario = params['id_formulario'];
			this._cursoService.getRespuesta(url_curso,url_etapa,url_formulario,id_formulario).subscribe(
				response => {
					if(!response.respuesta.pregunta){
						this._router.navigate(['/formulario',url_curso,url_etapa,url_formulario]);
					}else{
						this.url_curso_a = url_curso;
						this.url_etapa_a = url_etapa;
						this.url_formulario_c = url_formulario;
						this.datos = response.respuesta.pregunta;
					}
				},
				error => {
					this._router.navigate(['/formulario',url_curso,url_etapa,url_formulario]);
				}
			);
		});
	}
	onSubmit(preguntaForm: NgForm){
		this._route.params.forEach((params: Params) =>{
			this.respuesta = preguntaForm.value;
			let url_curso = params['url_curso'];
			let url_etapa = params['url_etapa'];
			let url_formulario = params['url_formulario'];
			let id_formulario = params['id_formulario'];
			this._cursoService.register(this.respuesta,url_curso,url_etapa,url_formulario,id_formulario).subscribe(
				response => {
					if(response.mensaje.codigo == 'success'){
						this._router.navigate(['/formulario',url_curso,url_etapa,url_formulario]);
					}else{
						this.cod = response.mensaje.codigo;
					    this.mensaje = response.mensaje.msg;
					}
				}, 
				error =>{
					this.cod = error._body.mensaje.codigo;
					this.mensaje = error._body.mensaje.msg;
				}
			);
		})
	}
}