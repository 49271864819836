import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CursoService } from '../../services/curso.service';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
	selector: 'listado-cursos-id-url-leccion',
	templateUrl: './listado-curso-id-url-leccion.component.html'
})

export class ListadoCursoIdUrlLeccionComponent implements OnInit{
	  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  
    .pipe(
      map(result => result.matches)
    );
	public leccion:string;
	public cont:string;
	public url_curso_a:string;
	public url_etapa_a:string;
	public url_leccion_a:string;
	public descripcion;
	constructor(private _route: ActivatedRoute, private _router: Router, private _cursoService: CursoService, private breakpointObserver: BreakpointObserver){
	}	
	ngOnInit(){
		this.getListadoCursosIdUrlLeccion();
	}
	getListadoCursosIdUrlLeccion(){
		this._route.params.forEach((params: Params) =>{
			let url_curso = params['url_curso'];
			let url_etapa = params['url_etapa'];
			let url_leccion = params['url_leccion'];
			this._cursoService.getListadoCursosIdUrlLeccion(url_curso,url_etapa,url_leccion).subscribe(
				response => {
					if(!response.mensaje.lec){
						this._router.navigate(['/curso']);
					}else{
						this.url_curso_a = url_curso;
						this.url_etapa_a = url_etapa;
						this.url_leccion_a = url_leccion;
						this.leccion = response.mensaje.lec;
						this.cont = response.mensaje.cont;
						this.descripcion = response.mensaje.lec.descripcion;
					}
				},
				error => {
					this._router.navigate(['/curso']);
				}
			);
		});
	}
}