import { Component, OnInit, ViewChild, DoCheck } from '@angular/core';
import { UsuarioService } from './services/usuario.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {MatSidenav} from '@angular/material/sidenav';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, DoCheck{

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  
    .pipe(
      map(result => result.matches)
    );

  public title:string;
  public identity;

  constructor(private _usuarioService: UsuarioService, private _router: Router, private breakpointObserver: BreakpointObserver){
  	this.title = 'Curso de Webapp';
  }
  ngOnInit(){
  	this.identity = this._usuarioService.getIdentity();
    if(this._usuarioService.getToken() === null){
      this.logout();
    }
  }
  ngDoCheck(){
  	this.identity = this._usuarioService.getIdentity();
  }
  logout(){
  	localStorage.clear();
  	this.identity = null;
  	this._router.navigate(['/login']);
  }
}
