import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { InicioComponent} from './component/inicio/inicio.component';
import { AlumnosComponent } from './component/alumnos/alumnos.component';
import { CursoComponent} from './component/curso/curso.component';
import { LoginComponent} from './component/login/login.component';
import { RegistroComponent} from './component/registro/registro.component';
import { UsuarioEditComponent} from './component/usuario-edit/usuario-edit.component';
import { ListadoCursoIdComponent } from './component/listado-curso-id/listado-curso-id.component';
import { ListadoCursoIdUrlComponent } from './component/listado-cursos-id-url/listado-cursos-id-url.component';
import { FormularioComponent } from './component/formulario/formulario.component';
import { FormularioRespuestaComponent } from './component/formulario-respuesta/formulario-respuesta.component';
import { FormularioRespuestaVerComponent } from './component/formulario-respuesta-ver/formulario-respuesta-ver.component';
import { ListadoCursoIdUrlLeccionComponent } from './component/listado-curso-id-url-leccion/listado-curso-id-url-leccion.component';
import { LeccionEtapaComponent } from './component/leccion-etapa/leccion-etapa.component';
import { ListaAlumnosFormulariosComponent } from './component/listado-alumnos-formularios/listado-alumnos-formularios.component';
import { ListaAlumnosFormulariosVerComponent } from './component/listado-alumnos-formularios-ver/listado-alumnos-formularios-ver.component';
import { ListaAlumnosFormulariosVerIdComponent } from './component/listado-alumnos-formularios-ver-id/listado-alumnos-formularios-ver-id.component';


import { AdminGuard } from './services/admin.guard';
import { RoleGuard } from './services/role.guard';

const appRoutes: Routes = [
	{path: '', component: LoginComponent},
	{path: '', redirectTo: 'login', pathMatch: 'full'},
	{path: 'inicio', component: InicioComponent},
	{path: 'curso', component: CursoComponent, canActivate: [AdminGuard] },
	{path: 'listado-curso-id/:url_curso', component: ListadoCursoIdComponent, canActivate: [AdminGuard] },
	{path: 'listado-curso-id/:url_curso/:url_etapa', component: ListadoCursoIdUrlComponent, canActivate: [AdminGuard] },
	{path: 'listado-curso-id/:url_curso/:url_etapa/:url_leccion', component: ListadoCursoIdUrlLeccionComponent, canActivate: [AdminGuard] },
	{path: 'listado-curso-id/:url_curso/:url_etapa/:url_leccion/:url_etapa_leccion', component: LeccionEtapaComponent, canActivate: [AdminGuard] },
	{path: 'formulario/:url_curso/:url_etapa/:url_formulario', component: FormularioComponent, canActivate: [AdminGuard] },
	{path: 'formulario/:url_curso/:url_etapa/:url_formulario/:id_formulario', component: FormularioRespuestaComponent, canActivate: [AdminGuard] },
	{path: 'formulario-ver/:url_curso/:url_etapa/:url_formulario/:id_formulario', component: FormularioRespuestaVerComponent, canActivate: [AdminGuard] },
	{path: 'listado-alumnos-formularios/:url_usuario', component: ListaAlumnosFormulariosComponent, canActivate: [RoleGuard]},
	{path: 'listado-alumnos-formularios-ver/:url_formulario/:url_usuario', component: ListaAlumnosFormulariosVerComponent, canActivate: [RoleGuard]},
	{path: 'listado-alumnos-formularios-ver-id/:url_formulario/:url_usuario/:id_respuesta', component: ListaAlumnosFormulariosVerIdComponent, canActivate: [RoleGuard]},

	{path: 'login', component: LoginComponent},
	{path: 'alumnos', component: AlumnosComponent, canActivate: [RoleGuard]},
	{path: 'registro', component: RegistroComponent},
	{path: 'editar-datos', component: UsuarioEditComponent, canActivate: [AdminGuard] },
	{path: '**', component: InicioComponent},
];

export const appRoutingProviders: any[] = [];
export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes, { enableTracing: true, useHash:true });