import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { GLOBAL } from '../../services/global';
import { AlumnoService } from '../../services/alumno.service';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Component({
	selector: 'listado-alumnos-formularios',
	templateUrl: './alumnos.component.html'
})

export class ListaAlumnosFormulariosComponent implements OnInit{
	  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  
    .pipe(
      map(result => result.matches)
    );
	public title:string;
	public data:string;
	public url_usuario_enviar:string;
	constructor(private _route: ActivatedRoute, private _router: Router, private _alumnoService: AlumnoService, private breakpointObserver: BreakpointObserver){
		this.title = 'Formularios';	
	}
	ngOnInit(){
		this._route.params.forEach((params: Params) =>{
			let url_usuario = params['url_usuario'];
			this._alumnoService.getListaAlumnosFormulario().subscribe(
			response => {
				if(!response.dato){
				}else{
					this.data = response.dato;
					this.url_usuario_enviar = url_usuario;
				}
			},
			error => {
				this._router.navigate(['/inicio']);
			}
			);
		});
	}
}