import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { map } from 'rxjs/operators/map'; 
import { Observable } from 'rxjs/Observable';
import { GLOBAL } from './global';	

@Injectable()
export class AlumnoService{

	public url: string;
	public identity;
	public token;

	constructor(private _http: Http){
		this.url = GLOBAL.url;
	}
	getToken(){
		let token = localStorage.getItem('token');
		if(token != "undefined"){
			this.token = token;
		}else{
			this.token = null;
		}
		return this.token;
	}
	getListaAlumnos(){
		let headers = new Headers({'Content-Type':'application/json','Authorization': this.getToken()});
		let options = new RequestOptions({headers: headers});
		return this._http.get(this.url+'alumnos/listadoAlumnos', options).pipe(map(res => res.json()));
	}
	getListaAlumnosFormulario(){
		let headers = new Headers({'Content-Type':'application/json','Authorization': this.getToken()});
		let options = new RequestOptions({headers: headers});
		return this._http.get(this.url+'alumnos/listadoAlumnosFormulario', options).pipe(map(res => res.json()));
	}
	getListaAlumnosFormularioVer(url_formulario,url_usuario){
		let headers = new Headers({'Content-Type':'application/json','Authorization': this.getToken()});
		let options = new RequestOptions({headers: headers});
		return this._http.get(this.url+'alumnos/listadoAlumnosFormularioVer/'+url_formulario+'/'+url_usuario, options).pipe(map(res => res.json()));
	}
	getListaAlumnosFormularioVerId(url_formulario,url_usuario,id_respuesta_formulario){
		let headers = new Headers({'Content-Type':'application/json','Authorization': this.getToken()});
		let options = new RequestOptions({headers: headers});
		return this._http.get(this.url+'alumnos/listadoAlumnosFormularioVerId/'+url_formulario+'/'+url_usuario+'/'+id_respuesta_formulario, options).pipe(map(res => res.json()));
	}
	actualizarRespuesta(respuesta,url_formulario,url_usuario,id_respuesta_formulario){
		let params = JSON.stringify(respuesta);
		let headers = new Headers({'Content-Type':'application/json','Authorization': this.getToken()});
		return this._http.post(this.url+'alumnos/actualizarRespuestaFormulario/'+url_formulario+'/'+url_usuario+'/'+id_respuesta_formulario, params, {headers: headers}).pipe(map(res => res.json()));		 
		//return "Texto desde el Servicio";
	}
}