import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CursoService } from '../../services/curso.service';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
	selector: 'formulario-respuesta-ver',
	templateUrl: './formulario-respuesta-ver.component.html'
})

export class FormularioRespuestaVerComponent implements OnInit{
	isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  
    .pipe(
      map(result => result.matches)
    );
	public formulario:string;
	public url_curso_a:string;
	public url_etapa_a:string;
	public url_formulario_c:string;
	public datos:string;
	public respuesta:string;
	constructor(private _route: ActivatedRoute, private _router: Router, private _cursoService: CursoService, private breakpointObserver: BreakpointObserver){
	}	
	ngOnInit(){
		this.getListadoFormularioRespuesta();
	}
	getListadoFormularioRespuesta(){
		this._route.params.forEach((params: Params) =>{
			let url_curso = params['url_curso'];
			let url_etapa = params['url_etapa'];
			let url_formulario = params['url_formulario'];
			let id_formulario = params['id_formulario'];
			this._cursoService.getRespuestaVer(url_curso,url_etapa,url_formulario,id_formulario).subscribe(
				response => {
					if(!response.respuesta.pregunta){
						this._router.navigate(['/formulario',url_curso,url_etapa,url_formulario]);
					}else{
						this.url_curso_a = url_curso;
						this.url_etapa_a = url_etapa;
						this.url_formulario_c = url_formulario;
						this.datos = response.respuesta.pregunta;
						this.respuesta = response.respuesta.respuesta;
					}
				},
				error => {
					this._router.navigate(['/formulario',url_curso,url_etapa,url_formulario]);
				}
			);
		});
	}
}